import React, { forwardRef } from 'react';
import cls from 'classnames';
import Imgix, { SharedImigixAndSourceProps } from 'react-imgix';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

interface LazyImgixProps {
  alt?: string;
  className?: string;
  imgixParams?: SharedImigixAndSourceProps['imgixParams'];
  itemProp?: string;
  src?: string;
  sizes?: string;
  width?: number;
  height?: number;
}

export const LazyImgix = forwardRef<HTMLImageElement, LazyImgixProps>(
  ({ alt, className, imgixParams, itemProp, src, sizes, width, height }, ref ) => {
    if ( !src ) return null;

    return (
      <Imgix
        className={cls( 'lazyload', className )}
        htmlAttributes={{
          itemProp,
          alt,
          ref: ref as any,
        }}
        src={src}
        sizes={sizes}
        width={width}
        height={height}
        imgixParams={imgixParams}
        attributeConfig={{
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        }}
      />
    );
  }
);
