import React, { SyntheticEvent } from 'react';

interface SvgUseProps {
  ariaHidden?: 'true' | 'false';
  ariaLabel?: string;
  className?: string;
  height?: number;
  name: string;
  role?: string;
  viewBox?: string;
  width?: number;
  onClick?: ( event: SyntheticEvent ) => void;
}

export const SvgUse = ({
  ariaHidden,
  ariaLabel,
  className,
  height,
  name,
  role,
  viewBox,
  width,
  onClick,
}: SvgUseProps ) => (
  <svg
    aria-hidden={ariaHidden}
    aria-label={ariaLabel}
    className={className}
    height={height}
    name={name}
    role={role !== 'presentation' ? role || 'img' : undefined}
    viewBox={viewBox}
    width={width}
    onClick={onClick}
  >
    <use
      role={role === 'presentation' ? 'presentation' : undefined}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref={`#${name}`}
    />
  </svg>
);
