import React, { useMemo } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

import { SvgUse } from 'src/shoppe/elements/SvgUse';

import styles from './index.module.sass';

const SvgIcon = ({
  baseline, icon, iconClasses = '', svgClasses = ''
}) => {
  const parsedSvgClasses = useMemo(() => {
    let classes = `svg-icon svg-icon--${icon}`;

    if ( svgClasses ) {
      classes += ` ${svgClasses}`;
    }

    if ( baseline ) {
      classes += ' svg-icon--baseline';
    }

    return classes;
  }, [ baseline, icon, svgClasses ]);

  // We don't render anything if no icon was provided
  if ( !icon ) {
    return null;
  }

  return (
    <div className={`${styles.icon} ${parsedSvgClasses}`}>
      <SvgUse
        ariaHidden="true"
        className={cls( styles.graphic, iconClasses, {
          [styles.baseline]: baseline,
        })}
        name={icon}
        role="presentation"
      />
    </div>
  );
};

SvgIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  baseline: PropTypes.bool,
};

export default SvgIcon;
